// $(".menu").classList.contains("opened");
let menuOpen = false;
$(".menu").on("click", () => {
    if (!menuOpen) {
        $(".mobile-nav-wrapper").css("top", "0vh");
        menuOpen = true;
    } else if (menuOpen) {
        $(".mobile-nav-wrapper").css("top", "-100vh");
        menuOpen = false;
    }
});
